import { createDeepEqualSelector } from '@sit/client-shared';
import { getDimensionAutofillValueId } from '../../containers/Timesheet/helpers';
import { objectHasProperty } from '../../helpers/objects';
import { ReduxState } from '../../types/redux/state';
import { AutofillStore } from '../../types/redux/stores/autofill.types';

const getAutofill = (state: ReduxState): AutofillStore => state.autofill;

export const selectRowAutofill = createDeepEqualSelector(
  getAutofill,
  (state: ReduxState, rowId: string | null) => rowId,
  (autofill, rowId) => (rowId && objectHasProperty(autofill, rowId) ? autofill[rowId] : null),
);

export const selectDimensionAutofillByDimensionId = createDeepEqualSelector(
  getAutofill,
  (state: ReduxState, dimensionId: string | null, entryId: string | null, rowId: string | null) => ({ dimensionId, entryId, rowId }),
  (autofill, { dimensionId, entryId, rowId }) => {
    const rowAutofill = rowId && objectHasProperty(autofill, rowId) ? autofill[rowId] : null;
    return getDimensionAutofillValueId(dimensionId, entryId, rowAutofill);
  },
);
