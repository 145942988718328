import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  SummaryDateItem,
  SummaryDurationItem,
  SummaryItem,
  SummaryPillStatusItem,
  SummaryPreviewItem,
} from '@web/components/Timesheet/SummaryCards';
import { parse } from 'date-fns';

const makeToSummaryItem =
  <T extends SummaryItem>(typename: T['__typename']) =>
  (label: T['label'], value: T['value'], stick = false, testId?: string): T =>
    ({
      __typename: typename,
      label,
      value,
      stick,
      testId,
    }) as T;

const toDateSummaryItem = makeToSummaryItem<SummaryDateItem>('date');
const toDurationSummaryItem = makeToSummaryItem<SummaryDurationItem>('duration');
const toPillStatusSummaryItem = makeToSummaryItem<SummaryPillStatusItem>('pill-status');
const toPreviewItem = makeToSummaryItem<SummaryPreviewItem>('preview');

interface UseSummaryDataProps {
  startDate: string;
  endDate: string;
  glPostDate: string | null;
  status: string;
  showBillable: boolean;
  total: number;
  billable: number;
  nonBillable: number;
}

export const useSummaryData = (props: UseSummaryDataProps | undefined): SummaryItem[] => {
  const { _ } = useLingui();

  if (!props) {
    return [];
  }

  const { startDate, endDate, glPostDate, status, showBillable, total, billable, nonBillable } = props;

  return [
    ...(startDate
      ? [toDateSummaryItem(_(msg`Begin date`), parse(startDate, 'yyyy-MM-dd', new Date()))]
      : [toPreviewItem(_(msg`Begin date`))]),
    ...(endDate ? [toDateSummaryItem(_(msg`End date`), parse(endDate, 'yyyy-MM-dd', new Date()))] : [toPreviewItem(_(msg`End date`))]),
    toDurationSummaryItem(_(msg`Total hours`), total, undefined, 'total-hours'),
    ...(showBillable
      ? [
          toDurationSummaryItem(_(msg`Billable hours`), billable, undefined, 'total-billable'),
          toDurationSummaryItem(_(msg`Non-billable hours`), nonBillable, undefined, 'total-non-billable'),
        ]
      : []),
    ...(glPostDate
      ? [toDateSummaryItem(_(msg`GL post date`), parse(glPostDate, 'yyyy-MM-dd', new Date()))]
      : [toPreviewItem(_(msg`GL post date`))]),
    toPillStatusSummaryItem(_(msg`Timesheet status`), status),
  ];
};
