import StatusCard from './StatusCard';

import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.palette.slateTint(80)};
  overflow-x: auto;
`;

export interface SummaryItemBase<T, TypeName extends string> {
  label: string;
  value: T;
  stick?: boolean;
  withDivider?: boolean;
  testId?: string;
  __typename: TypeName;
}

export type SummaryDateItem = SummaryItemBase<Date, 'date'>;
export type SummaryDurationItem = SummaryItemBase<number, 'duration'>;
export type SummaryPillStatusItem = SummaryItemBase<string, 'pill-status'>;
export type SummaryPreviewItem = SummaryItemBase<void, 'preview'>;

export type SummaryItem = SummaryDateItem | SummaryDurationItem | SummaryPillStatusItem | SummaryPreviewItem;

const SummaryItemStat = ({ stick = false, ...item }: SummaryItem): JSX.Element => {
  const stickAt = stick ? 'right' : undefined;

  switch (item.__typename) {
    case 'date':
      return <StatusCard data-testid={item.testId} type="date" {...{ stickAt, ...item }} />;
    case 'duration':
      return <StatusCard data-testid={item.testId} type="duration" {...{ stickAt, ...item }} />;
    case 'pill-status': {
      return <StatusCard data-testid={item.testId} type="pill-status" {...{ stickAt, ...item }} />;
    }
    case 'preview': {
      return <StatusCard data-testid={item.testId} type="preview" {...{ stickAt, ...item }} />;
    }
  }
};

interface SummaryCardsProps {
  items: SummaryItem[];
}

const SummaryCards = ({ items: [firstItem, ...remainingItems] }: SummaryCardsProps) => {
  return (
    <Root>
      <SummaryItemStat {...firstItem} />
      {remainingItems.map((item, index) => (
        <SummaryItemStat key={index} withDivider {...item} />
      ))}
    </Root>
  );
};

export default SummaryCards;
