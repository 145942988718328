import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebFeatureFlagNames, WebFeatureFlags } from '@web/launchDarkly/flags';
import { logout } from '../authentication-slice';
import { BUILD_TYPES, BuildType, DevOverrides } from './types';

const getBuildTypeName = (): BuildType => {
  const variant = (import.meta.env.VITE_APP_BUILD_TYPE as BuildType | undefined) ?? BuildType.default;

  if (!BUILD_TYPES.includes(variant)) {
    throw new Error(`Unknown variant ${variant}`);
  }
  return variant || BuildType.default;
};

const getEnvFlagValue = <K extends WebFeatureFlagNames>(key: K): WebFeatureFlags[K] | undefined => {
  const rawValue = import.meta.env[`VITE_APP_FF_${key}`];

  if (rawValue === undefined) {
    return;
  }

  return JSON.parse(rawValue);
};

const getEnvFlags = () => {
  return Object.keys(import.meta.env)
    .filter((key) => key.startsWith('VITE_APP_FF_'))
    .reduce<Partial<WebFeatureFlags>>((rest, key) => {
      const flagName = key.replace('VITE_APP_FF_', '') as WebFeatureFlagNames;

      return {
        ...rest,
        [flagName]: getEnvFlagValue(flagName),
      };
    }, {});
};

interface DevelopmentStore {
  buildType: BuildType;
  devOverrides: DevOverrides;
}

const initialState: DevelopmentStore = {
  buildType: getBuildTypeName(),
  devOverrides: {
    dashboardVisible: false,
    featureFlags: getEnvFlags(),
  },
};

const developmentSlice = createSlice({
  name: 'development',
  initialState,
  reducers: {
    setBuildType: (state, action: PayloadAction<BuildType>) => {
      state.buildType = action.payload as DevelopmentStore['buildType'];
    },
    updateDevOverride: (state, action: PayloadAction<Partial<DevelopmentStore['devOverrides']>>) => {
      state.devOverrides = {
        ...state.devOverrides,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, () => initialState);
  },
});

export const { setBuildType, updateDevOverride } = developmentSlice.actions;
export default developmentSlice.reducer;
