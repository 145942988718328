/// <reference types="@types/new-relic-browser" />
import { reportError } from '@web/helpers/newrelic';
import * as React from 'react';
import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export interface ErrorBoundaryProps {
  children?: ReactNode;
  fallbackUI?: ReactNode | ((error: Error, resetError: () => void) => ReactNode);
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

const INITIAL_ERROR_STATE = { hasError: false, error: null } as const;

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { ...INITIAL_ERROR_STATE };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any child components and re-render with error message
    reportError(error, errorInfo.componentStack ? { componentStack: errorInfo.componentStack } : undefined);
  }

  resetError = (): void => {
    this.setState(INITIAL_ERROR_STATE);
  };

  render(): ReactNode {
    const { children, fallbackUI } = this.props;
    const { hasError, error } = this.state;
    if (!hasError || !error) return children;
    if (React.isValidElement(fallbackUI)) {
      const FallbackUI = fallbackUI as unknown as React.ElementType;
      return <FallbackUI error={error} resetError={this.resetError} />;
    }
    if (typeof fallbackUI === 'function') {
      const fallbackComponent = fallbackUI(error, this.resetError);
      return React.isValidElement(fallbackComponent) ? fallbackComponent : null;
    }
    // If the consumer wants an empty UI, they can pass in `<></>` as the `fallbackUI`.
    return <ErrorMessage error={error} />;
  }
}
