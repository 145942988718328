import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from '../authentication-slice';

interface AutofillState {
  [rowId: string]: {
    [dimensionId: string]: {
      allowOverride: boolean;
      order: number;
      valueId: string;
      from: string;
    }[];
  };
}

const initialState: AutofillState = {};

const autofillSlice = createSlice({
  name: 'autofill',
  initialState,
  reducers: {
    resetAutofill: () => initialState,
    setAutofill: (state, { payload }: PayloadAction<{ a: any; rowId: string; from: string }>) => {
      const { a, rowId, from } = payload;
      if (!rowId) {
        return state;
      }
      const { dimensionId, fixedDimension } = a;
      const relevantDimension = fixedDimension || dimensionId;
      const prevValues = state[rowId] ? state[rowId][relevantDimension] || [] : [];
      const alreadyAdded = prevValues.find(
        (v) => v.allowOverride === a.allowOverride && v.order === (a.order || 0) && v.valueId === a.valueId,
      );
      if (alreadyAdded) {
        return state;
      }
      state[rowId] = state[rowId] || {};
      state[rowId][relevantDimension] = state[rowId][relevantDimension] || [];
      state[rowId][relevantDimension] = [
        {
          allowOverride: a.allowOverride,
          order: a.order || 0,
          valueId: a.valueId,
          from,
        },
        ...prevValues,
      ];
      return state;
    },
    removeRow: (state, { payload }: PayloadAction<{ rowId: string }>) => {
      delete state[payload.rowId];
      return state;
    },
    removeAutofillValue: (state, { payload }: PayloadAction<{ from: string | null; rowId: string }>) => {
      const { from, rowId } = payload;
      if (!rowId || !from || !state[rowId]) {
        return state;
      }
      // Search every dimension autofills where from === from and delete the autofill data
      const row = state[rowId];
      for (const key of Object.keys(row)) {
        const newAutofills = row[key].filter((a) => a.from !== from);
        row[key] = newAutofills;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.type, () => initialState);
  },
});

export const { resetAutofill, setAutofill, removeRow, removeAutofillValue } = autofillSlice.actions;
export default autofillSlice.reducer;
